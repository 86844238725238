import React, { useRef } from "react";
import styled from "styled-components";

const NavigationBar = () => {

	const navLinksRef = useRef();

	const toggleNavigationList = () => {

		const x = navLinksRef.current;

		if (x.className === "flex-box nav-links-container") {
		    x.className += " responsive";
		} else {
		    x.className = "flex-box nav-links-container";
		}
	}

	return (
		<NavStyles className="flex-box">
	  		<div className="flex-box logo-container">
	  			<img className="logo" alt="company-logo" src={require("../img/logo.png")}/>
	  		</div>
	  		<ul ref={navLinksRef} className="flex-box nav-links-container">
	  			<li><a href="#!" className="nav-links bold">Home</a></li>
	  			<li><a href="#!" className="nav-links">Build Trip</a></li>
	  			<li><a href="#!" className="nav-links">Calgary Stories</a></li>
	  			<li><a href="#!" className="nav-links">Events</a></li>
	  			<li><a href="#!" style={{marginLeft: "80px"}} className="nav-links bold">Sign In</a></li>
	  		</ul>
	  		<a href="#!" className="icon push-right" style={{cursor: "pointer"}} onClick={toggleNavigationList}>
	  			<i className="fa fa-bars" style={{color: "rgba(0, 0, 0, 0.6)", fontSize: "2em"}}></i>
	  		</a>
  		</NavStyles>
	);
}

export default NavigationBar;

const NavStyles = styled.div`
	width: 100%;
	min-height: 14vh;
	min-width: 375px;

	position: sticky;
	z-index: 10;
	top: 0px;

	font-size: 16px;
	font-family: "Noto Sans";

	background: white;
	box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.3);

	z-index: 20;


	align-items: center;

	.nav-links-container {
		width: 100%;
		justify-content: center;
		flex-direction: row;
		align-items: center;
	}

	a {
		color: rgba(0, 0, 0, 0.55);
	}

	.logo-container {
		min-height: 14vh;
		position: absolute;
		top: 0;
		left: 0;
		align-items: center;
	}

	.logo {
		height: 96px;
		width: auto;

		position: relative;
		// left: 0;
		// top: 0;

		margin-left: 36px;
	}

	.icon {
		margin-right: 30px;
		display: none;
	}

	@media screen and (max-width: 960px) {
		.nav-links-container {
			display: none;
		}

		.icon {
			display: block;
		}

		.logo {
			margin-left: 15px;
		}
	}

	@media screen and (max-width: 960px) {
		.nav-links-container.responsive {
			position: absolute;
			top: 0;

			display: block;
			margin-top: 14vh;
			background: rgba(240, 240, 240, 1);
			width: 100%;
			padding: 8px;

			box-shadow: 0px 8px 8px 2px rgba(0, 0, 0, 0.3);

			a {
				margin-left: 10px !important;
			}

			li {
				margin: 5px 0 !important;
			}
		}
	}

	@media screen and (max-width: 500px) {
		.logo {
			height: 86px;
		}
	}
`;

