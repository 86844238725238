import NavigationBar from "./components/NavigationBar";
import LandingPage from "./pages/landing-page.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
    	<NavigationBar />
    	<Router>
    		<Routes>
    			<Route exact path="/" element={<LandingPage />} />
    		</Routes>
    	</Router>     
    </div>
  );
}

export default App;
