import React from "react";
import styled from "styled-components";

const PageContainer = ({ children }) => {

	return (
		<PageContainerStyl>
			{children}
		</PageContainerStyl>
	);
}

export default PageContainer;

const PageContainerStyl = styled.div`
	padding-top: 20px;
	min-width: 375px;
`;