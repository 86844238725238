import React from "react";
import Card from "../components/Card";
import Footer from "../components/Footer";
import PageContainer from "../components/PageContainer";
import styled from "styled-components";
import Button from 'react-bootstrap/Button';

const LandingPage = () => {
	return (
		<PageContainer>		
			<LandingPageMainImageContainer>
				<div className="inner-container">
					<img className="landing-image" alt="" src={require("../img/main-image.png")}/>
					<div className="landing-image-overlay">
						<p>Discover. Experience. Connect.</p>
					</div>
				</div>
			</LandingPageMainImageContainer>
			<SurveyBtnWrapper className="flex-center">
				<div className="btn-outer-container">
					<div className="btn-inner-container">
						
						<Button className="btn-survey" variant="primary">
							I am Local - Ready for my perfect day!
						</Button>{' '}
				
						<Button className="btn-survey" variant="primary" href="https://www.figma.com/proto/LmjaNssVyk8qvjkR8ru5K3/YYC-HackApp-Design?page-id=0%3A1&node-id=18%3A33611&viewport=338%2C495%2C0.11&scaling=min-zoom&starting-point-node-id=18%3A33611&show-proto-sidebar=1&hide-ui=1">
							From Out of Town - Coming in Hot!
						</Button>{' '}
						
					</div>
				</div>
			</SurveyBtnWrapper>
			<ReviewCardsWrapper className="flex-center">
				<Card title="'A real sense of community, nurtured'" 
					  subtitle="Really appreciate the help and support from the staff during these tough times. Shoutout to Katie..." 					  
					  imgalt="image1" 
					  img={require("../img/pic-1.png")}
					  rating={5}
				/>
				<Card title="'The facilities are superb, clean, and bright.'" 
					  subtitle="Really appreciate the help and support from the staff during..."	  
					  imgalt="image1" 
					  img={require("../img/pic-2.png")}
					  rating={4}
				/>
				<Card title="'The facilities are superb, clean, and bright.'" 
					  subtitle="Really appreciate the help and support from the staff during..."	  
					  imgalt="image1" 
					  img={require("../img/pic-3.jpeg")}
					  rating={5}
				/>
			</ReviewCardsWrapper>
			<Footer />
		</PageContainer>
	);
}

export default LandingPage;


const LandingPageMainImageContainer = styled.div`

	display: flex;
	justify-content: center;

	.inner-container {
		position: relative;
		display: flex;

		background: linear-gradient(360deg, rgba(0, 0, 0, 0) 15.58%, rgba(0, 0, 0, 0.8) 115.58%);

		border-width: 4px;
		border-radius: 10px;
	}

	.landing-image {
		height: 67vh;
		width: auto;

		border-radius: 10px;
		padding: 8px;
	}

	.landing-image-overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		background: linear-gradient(360deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%);

		z-index: 10;

		border-radius: 10px;

		p {
			text-align: center;
			color: white;
			font-size: 2.8em;
			font-weight:bold;

			margin-top: 180px;

			font-family: "Roboto";
		}
	}

	@media screen and (max-width: 960px) {
		.landing-image {
			width: 100%;
			height: auto;
		}

		.inner-container {
			margin: 0 20px;
		}
	}

	@media screen and (max-width: 750px) {
		.landing-image-overlay {
			p {
				font-size: 2em;
				margin-top: 130px;
			}
		}

		.inner-container {
			margin: 0 10px;
		}
	}

	@media screen and (max-width: 600px) {
		.landing-image-overlay {
			p {
				font-size: 1.6em;
				margin-top: 110px;
			}
		}
	}

	@media screen and (max-width: 480px) {
		.landing-image-overlay {
			p {
				font-size: 1.2em;
				margin-top: 90px;
			}
		}
	}
`;


const SurveyBtnWrapper = styled.div`

	margin: 30px 0;

	.btn-outer-container {
		width: 900px;
		padding: 0 20px;
	}

	.btn-inner-container {
		display: flex;
		flex-direction: row; 
		justify-content: center;
	}

	.btn-survey {
		border-radius: 20px;
		padding-top: 20px;
		padding-bottom: 20px;

		width: 48% !important;
		margin: 0 33px;

		background: hsl(211, 97%, 34%);
	}

	.btn-survey:hover {
		background: hsl(211, 97%, 45%);
	}

	@media screen and (max-width: 960px) {
		.btn-outer-container {
			width: 100%;
		}

		.btn-inner-container {
			flex-direction: column;
		}

		.btn-survey {
			width: 100% !important;
			margin: 10px 0;
		}
	}

	@media screen and (max-width: 480px) {
		margin: 10px 0;

		.btn-survey {
			font-size: 14px;
		}
	}
`;

const ReviewCardsWrapper = styled.div`
	@media screen and (max-width: 960px) {
		flex-direction: column;
		align-items: center;
	}
`;